@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&family=DM+Sans:wght@400;700&display=swap");
@import "./themes.css";

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

/* remove number increment arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* Custom styles */
.debug {
  @apply !bg-red-500/80 !text-red-200;
}
.page {
  @apply bg-primary-900 min-h-screen overscroll-none;
}
.mono-body {
  font-family: "DM Mono", monospace;
}
.card {
  @apply rounded-xl overflow-hidden border border-primary-700;
}
.card.standard {
  @apply bg-primary-800;
}
.card.hoverable {
  @apply hover:border-primary-600 hover:shadow-lg;
}
.area.hoverable {
  @apply hover:bg-primary-100/5 cursor-pointer;
}
.text.hoverable {
  @apply hover:text-accent-100;
}
.subtext {
  @apply text-xs text-primary-500;
}
.focus-glow {
  @apply focus:outline-none focus:ring-1 focus:ring-accent-100 focus:ring-opacity-90;
}
.accent-hover {
  @apply hover:bg-accent-100/20 hover:text-accent-100 cursor-pointer;
}
.icon-box {
  @apply accent-hover group-hover:bg-accent-100 bg-primary-600 w-6 h-6 rounded-md mr-3;
}
.soft-entrance {
  @apply animate-in fade-in zoom-in-75 duration-200;
}
